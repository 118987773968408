import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { useMediaQuery } from "@react-hook/media-query"
import Layout from "../layouts"
import stripHtml from "../libs/strip-html"
import Work from "../components/work"
import BackButton from "../components/back-button"
import BackgroundSection from "../components/background-section"

const StyledBackgroundSection = styled(BackgroundSection)`
  &:not(:first-of-type) {
    margin-top: 2em;
  }
`
export default ({ data }) => {
  const mobile = useMediaQuery("only screen and (max-width: 769px)")
  const { author, members, works } = data
  return (
    <Layout title={author.title} description={stripHtml(author.excerpt)}>
      <BackgroundSection image={author.featured_media.localFile.childImageSharp.fluid}>
        <div className="container is-widescreen">
          <div className="head">
            <BackButton/>
            <h1 className="title is-1 has-text-primary">{author.title}</h1>
            {(author.short || author.country) && (
              <p className="subtitle is-4 mb-0">
                {author.short && <span className="item">{author.short}</span>}
                {author.country && <span className="item">{author.country}</span>}
              </p>
            )}
            {author.website && (
              <p className="links">
                <span className="item">
                  <a href={author.website}>Personal website</a>
                </span>
              </p>
            )}
          </div>
        </div>
        {mobile && <Img fluid={author.featured_media.localFile.childImageSharp.fluid} fadeIn/>}
        <div className="container is-widescreen">
          <div className="columns">
            <div className="column is-6">
              <h2 className="title is-5 has-text-primary mb-1">Bio</h2>
              <div className="content" dangerouslySetInnerHTML={{ __html: author.content }}/>
              <div className="socials">
                {author.facebook && (
                  <a target="_blank" rel="noreferrer" href={author.facebook} aria-label="Facebook"><i
                    className="fab fa-facebook"/></a>)}
                {author.imdb && (
                  <a target="_blank" rel="noreferrer" href={author.imdb} aria-label="Imdb"><i className="fab fa-imdb"/></a>)}
                {author.instagram && (
                  <a target="_blank" rel="noreferrer" href={author.instagram} aria-label="Instagram"><i
                    className="fab fa-instagram"/></a>)}
                {author.linkedin && (
                  <a target="_blank" rel="noreferrer" href={author.linkedin} aria-label="Linkedin"><i
                    className="fab fa-linkedin"/></a>)}
                {author.wikipedia && (
                  <a target="_blank" rel="noreferrer" href={author.wikipedia} aria-label="Wikipedia"><i
                    className="fab fa-wikipedia-w"/></a>)}
              </div>
            </div>
          </div>
        </div>
      </BackgroundSection>
      {/*PROJECTS*/}
      <div className="container is-widescreen">
        <h2 className="title is-2 has-text-centered has-text-primary">Projects</h2>
        <div className="columns is-multiline">
          {works.nodes.map((work, index) => (<Work work={work} key={work.id} index={index}/>))}
        </div>
      </div>
      {/*MEMBERS*/}
      {members.nodes.length > 0 && (
        <div className="member">
          <div className="container is-widescreen">
            <h2 className="title is-2 has-text-centered has-text-primary">Members</h2>
          </div>
          {members.nodes.map(member => (
            <StyledBackgroundSection
              image={member.featured_media.localFile.childImageSharp.fluid}
              opacity="0.5"
              styles={{ minHeight: 200 }}
            >
              <div className="container is-widescreen">
                <div className="head" style={{ marginBottom: 0 }}>
                  <h3 className="title is-3 has-text-primary" style={{ marginTop: ".75em", marginBottom: 0 }}>
                    {member.title}
                  </h3>
                  {(member.short || member.country) && (
                    <p className="subtitle is-4">
                      {member.short && <span className="item">{author.short}</span>}
                      {member.country && <span className="item">{author.country}</span>}
                    </p>
                  )}
                  {(member.website) && (
                    <p className="links">
                    <span className="item">
                      <a target="_blank" rel="noreferrer" href={member.website}>Personal website</a>
                    </span>
                    </p>
                  )}
                </div>
              </div>
              {mobile && <Img fluid={member.featured_media.localFile.childImageSharp.fluid} fadeIn/>}
              <div className="container is-widescreen">
                <div className="columns">
                  <div className="column is-6">
                    {member.content && (
                      <>
                        <h2 className="title is-5 has-text-primary mb-1">Bio</h2>
                        <div className="content" dangerouslySetInnerHTML={{ __html: member.content }}/>
                      </>
                    )}
                    <div className="socials">
                      {member.facebook && (
                        <a target="_blank" rel="noreferrer" href={member.facebook} aria-label="Facebook"><i
                          className="fab fa-facebook"/></a>)}
                      {member.imdb && (<a target="_blank" rel="noreferrer" href={member.imdb} aria-label="Imdb"><i
                        className="fab fa-imdb"/></a>)}
                      {member.instagram && (
                        <a target="_blank" rel="noreferrer" href={member.instagram} aria-label="Instagram"><i
                          className="fab fa-instagram"/></a>)}
                      {member.linkedin && (
                        <a target="_blank" rel="noreferrer" href={member.linkedin} aria-label="Linkedin"><i
                          className="fab fa-linkedin"/></a>)}
                      {member.wikipedia && (
                        <a target="_blank" rel="noreferrer" href={member.wikipedia} aria-label="Wikipedia"><i
                          className="fab fa-wikipedia-w"/></a>)}

                    </div>
                  </div>
                </div>
              </div>
            </StyledBackgroundSection>
          ))}
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
    query($slug: String!, $id: Int!) {
        author: wordpressWpArtist(slug: {eq: $slug}) {
            id: wordpress_id
            title
            content
            excerpt
            country
            short
            facebook
            imdb
            instagram
            linkedin
            website
            wikipedia
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(
                            maxWidth: 2000
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        members: allWordpressWpMember(
            sort: {fields: [priority, title]},
            filter: {parent_artist: {elemMatch: {wordpress_id: {eq: $id}}}}
        ) {
            nodes {
                id: wordpress_id
                title
                content
                country
                short
                facebook
                imdb
                instagram
                linkedin
                website
                wikipedia
                featured_media {
                    localFile {
                        childImageSharp {
                            fluid(
                                maxWidth: 2000
                            ) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
        works: allWordpressWpWork(
            sort: {fields: title}
            filter: {artist: {elemMatch: {wordpress_id: {eq: $id}}}}
        ) {
            nodes {
                id: wordpress_id
                title
                slug
                preview
                featured_media {
                    source_url
                    localFile {
                        childImageSharp {
                            resize(
                                width: 400
                                height: 225
                            ) {
                                src
                            }
                        }
                    }
                }
            }
        }
    }
`